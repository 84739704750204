'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _d3Selection = require('d3-selection');

var _d3Sankey = require('d3-sankey');

var _d3Color = require('d3-color');

var _d3Scale = require('d3-scale');

require('d3-transition');

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var defaults = {
  // target element or selector to contain the svg
  target: '#chart',

  // width of chart
  width: 800,

  // height of chart
  height: 320,

  // margin
  margin: {
    top: 1,
    right: 1,
    bottom: 10,
    left: 1
  },

  // y spacing between nodes
  nodePadding: 10,

  // node width
  nodeWidth: 15,

  // number of times the converging function computeNodeDepths is run
  iterations: 32
};

/**
 * Sankey.
 */

var Sankey = function () {

  /**
   * construct with given `config`.
   */
  function Sankey(config) {
    _classCallCheck(this, Sankey);

    Object.assign(this, defaults, config);
    this.init();
  }

  /**
   * Initialize chart.
   */


  _createClass(Sankey, [{
    key: 'init',
    value: function init() {
      var target = this.target;
      var width = this.width;
      var height = this.height;
      var margin = this.margin;
      var nodeWidth = this.nodeWidth;
      var nodePadding = this.nodePadding;

      var w = width - margin.left - margin.right;
      var h = height - margin.top - margin.bottom;

      this.chart = (0, _d3Selection.select)(target).attr('width', width).attr('height', height).append('g').attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')');

      this.sankey = (0, _d3Sankey.sankey)().nodeWidth(nodeWidth).nodePadding(nodePadding).size([w, h]);
    }

    /**
     * Create gradient identifier.
     */

  }, {
    key: 'gradientID',
    value: function gradientID(d) {
      return ('linkGrad-' + d.source.name + '-' + d.target.name).replace(/\s/g, '');
    }

    /**
     * Get node color from datum.
     */

  }, {
    key: 'nodeColor',
    value: function nodeColor(d) {
      var color = (0, _d3Scale.scaleOrdinal)().domain(['reception', 'setting', 'attack']).range(['#009688', '#8BC34A', '#FFC107']);

      if (d.value < 1) {
        d.color = '#bbb';
      } else {
        var category = d.name.replace(/ .*/, '').toLowerCase();
        d.color = color(category);
      }
      return d.color;
    }

    /**
     * render
     */

  }, {
    key: 'render',
    value: function render(data) {
      var _this = this;

      var options = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];
      var iterations = this.iterations;

      // get data

      this.sankey.nodes(data.nodes).links(data.links).layout(iterations);

      // linear gradient definitions
      var grads = this.chart.append('defs').selectAll('linearGradient').data(data.links, function (d) {
        return _this.gradientID;
      }).enter().append('linearGradient').attr('id', this.gradientID).attr('gradientUnits', 'userSpaceOnUse').attr('x1', function (d) {
        return d.source.x;
      }).attr('y1', function (d) {
        return d.source.y;
      }).attr('x2', function (d) {
        return d.target.x;
      }).attr('y2', function (d) {
        return d.target.y;
      });

      grads.html('').append('stop').attr('offset', '0%').attr('stop-color', function (d) {
        return _this.nodeColor(+d.source.x <= +d.target.x ? d.source : d.target);
      });

      grads.append('stop').attr('offset', '100%').attr('stop-color', function (d) {
        return _this.nodeColor(+d.source.x > +d.target.x ? d.source : d.target);
      });

      // links
      var path = this.sankey.link();

      this.chart.append('g').selectAll('.link').data(data.links, function (d) {
        return d.source.name + d.target.name;
      }).enter().append('path').attr('class', 'link').attr('d', path)
      // css properties require units. must use `10px` instead 10 like in older d3 versions
      // https://github.com/d3/d3-transition/issues/50
      .style('stroke-width', function (d) {
        return Math.max(1, d.dy) + 'px';
      }).style('stroke', function (d) {
        return 'url(#' + _this.gradientID(d) + ')';
      }).style('opacity', function (d) {
        return d.value < 1 ? 0.001 : null;
      })
      // .sort((a, b) => b.dy - a.dy)
      .append('title').text(function (d) {
        return d.source.name + ' -> ' + d.target.name + '\n' + d.value;
      });

      // nodes
      var node = this.chart.append('g').selectAll('.node').data(data.nodes, function (d) {
        return d.name;
      }).enter().append('g').attr('class', 'node').attr('transform', function (d) {
        return 'translate(' + d.x + ', ' + d.y + ')';
      });

      // rect inside node
      node.append('rect').attr('height', function (d) {
        return d.dy;
      }).attr('width', function () {
        return _this.sankey.nodeWidth();
      }).style('fill', this.nodeColor).style('stroke', function (d) {
        return d.value < 1 ? '#bbb' : (0, _d3Color.rgb)(d.color).darker(2);
      }).append('title').text(function (d) {
        return d.name + '\n' + d.value.toFixed();
      });

      // text inside node
      node.append('text').attr('x', -6).attr('y', function (d) {
        return d.dy / 2;
      }).attr('dy', '.35em').attr('text-anchor', 'end').attr('transform', null).style('fill', function (d) {
        return d.value < 1 ? '#bbb' : null;
      }).text(function (d) {
        return d.name;
      }).filter(function (d) {
        return d.x < _this.width / 2;
      }).attr('x', function () {
        return 6 + _this.sankey.nodeWidth();
      }).attr('text-anchor', 'start');
    }

    /**
     * update
     */

  }, {
    key: 'update',
    value: function update(data) {
      var _this2 = this;

      var iterations = this.iterations;


      this.sankey.nodes(data.nodes).links(data.links).layout(iterations);

      // gradients
      var grads = this.chart.select('defs').selectAll('linearGradient').data(data.links, function (d) {
        return _this2.gradientID;
      });

      // enter selection
      var gradsEnter = grads.enter().append('linearGradient').attr('id', this.gradientID).attr('gradientUnits', 'userSpaceOnUse').attr('x1', function (d) {
        return d.source.x;
      }).attr('y1', function (d) {
        return d.source.y;
      }).attr('x2', function (d) {
        return d.target.x;
      }).attr('y2', function (d) {
        return d.target.y;
      });

      gradsEnter.html('').append('stop').attr('offset', '0%').attr('stop-color', function (d) {
        return _this2.nodeColor(+d.source.x <= +d.target.x ? d.source : d.target);
      });

      gradsEnter.append('stop').attr('offset', '100%').attr('stop-color', function (d) {
        return _this2.nodeColor(+d.source.x > +d.target.x ? d.source : d.target);
      });

      // transition selection
      var gradsTransition = grads.transition().attr('x1', function (d) {
        return d.source.x;
      }).attr('y1', function (d) {
        return d.source.y;
      }).attr('x2', function (d) {
        return d.target.x;
      }).attr('y2', function (d) {
        return d.target.y;
      });

      gradsTransition.select('stop[offset="0%"]').attr('stop-color', function (d) {
        return _this2.nodeColor(+d.source.x <= +d.target.x ? d.source : d.target);
      });

      gradsTransition.select('stop[offset="100%"]').attr('stop-color', function (d) {
        return _this2.nodeColor(+d.source.x > +d.target.x ? d.source : d.target);
      });

      // exit selection
      grads.exit().remove();

      // links
      var path = this.sankey.link();

      var link = this.chart.selectAll('.link')
      // required for properly animating links. d3 needs it to keep reference.
      .data(data.links, function (d) {
        return d.source.name + d.target.name;
      });
      // sort sets order in which links are drawn. important for overlapping / mouse effects
      // .sort((a, b) => b.dy - a.dy)

      // animate new links
      link.enter().append('path').attr('class', 'link').attr('d', path)
      // css properties require units. must use `10px` instead 10 like in older d3 versions
      // https://github.com/d3/d3-transition/issues/50
      .style('stroke-width', function (d) {
        return Math.max(1, d.dy) + 'px';
      }).style('stroke', function (d) {
        return 'url(#' + _this2.gradientID(d) + ')';
      }).style('display', function (d) {
        return d.value < 1 ? 'none' : null;
      })
      // .sort((a, b) => b.dy - a.dy)
      .append('title').text(function (d) {
        return d.source.name + ' -> ' + d.target.name + '\n' + d.value;
      });

      // animate still existing links to new positions
      link.transition().attr('d', path).style('stroke-width', function (d) {
        return Math.max(1, d.dy) + 'px';
      }).style('stroke', function (d) {
        return 'url(#' + _this2.gradientID(d) + ')';
      }).style('opacity', function (d) {
        return d.value < 1 ? 0.001 : null;
      }).select('title').text(function (d) {
        return d.source.name + ' -> ' + d.target.name + '\n' + d.value;
      });

      // remove old links
      link.exit().transition().style('stroke-width', '0px').remove();

      // nodes
      var node = this.chart.selectAll('.node').data(data.nodes, function (d) {
        return d.name;
      });

      // animate still existing nodes to new positions
      node.transition().attr('transform', function (d) {
        return 'translate(' + d.x + ', ' + d.y + ')';
      });

      // get all rects
      var rect = this.chart.selectAll('rect').data(data.nodes, function (d) {
        return 'rect-' + d.name;
      });

      // animate still existing rects to new positions
      rect.style('fill', this.nodeColor).style('stroke', function (d) {
        return d.value < 1 ? '#bbb' : (0, _d3Color.rgb)(d.color).darker(2);
      }).transition().attr('height', function (d) {
        return d.dy;
      }).select('title').text(function (d) {
        return d.name + '\n' + d.value.toFixed();
      });

      // text
      var text = this.chart.selectAll('text').data(data.nodes, function (d) {
        return d.name;
      });

      // animate still existing text to new position
      text.style('fill', function (d) {
        return d.value < 1 ? '#bbb' : null;
      }).transition().attr('y', function (d) {
        return d.dy / 2;
      }).filter(function (d) {
        return d.x < _this2.width / 2;
      });
    }
  }]);

  return Sankey;
}();

exports.default = Sankey;